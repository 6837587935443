<!--  -->
<template>
  <div class=''>
    <div style="font-size: 28px;">我的成员管理</div>
    <!-- <button @click="$router.push({ name: 'examplePage3' })">跳转</button> -->
    <el-divider></el-divider>

    <el-input style="width: 500px" placeholder="请输入用户姓名查询" v-model="search" clearable>
    </el-input>
    <div align='right' class="indicate_legend">
      <span class="blank" style="background-color:#F6BEB8 ">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
      <span style=" "> 偏高</span>
      <span style=" ">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
      <span style="background-color:rgb(234 215 79) ">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
      <span style=" "> 偏低</span>

    </div>
    <el-table :data="tableData.filter(data => !search || data.realName.toLowerCase().includes(search.toLowerCase()))"
      style="width: 100%" border :header-cell-style="{ 'text-align': 'center' }" :cell-style="cellStyle">

      <el-table-column type="index" width="40"></el-table-column>


      <el-table-column label="用户姓名" prop="realName" width="180">
        <template slot-scope="scope">
          <el-col :span="15">
            <el-input :rows="1" placeholder="请输入内容" v-model="scope.row.realName">
            </el-input>
          </el-col>
          <el-col :span="5" :offset="1">
            <el-button @click="updateRealName(scope.row)" type="primary" plain size="small">保存</el-button>
          </el-col>
        </template>
      </el-table-column>
      <el-table-column label="手机号" prop="phone" width="120"></el-table-column>
      <!-- <el-table-column label="个人信息"  width="150">
          <template slot-scope="scope">
            <el-button @click="psInfo(scope.row)" type="primary">{{ '个人信息表' }}</el-button>
          </template>
        </el-table-column> -->

      <el-table-column label="孕婴项目" width="100">
        <template v-slot="scope3">
          <el-popover placement="right" trigger="click" width="auto">
            <div style="text-align: center;font-size: 20px">请选择查询项目</div>
            <!-- <el-button @click="getECG(scope3.row.uid)">
                静态心电
              </el-button>
              <el-button @click="getECGHolter(scope3.row.uid)">
                动态心电
              </el-button> -->
            <!-- <el-button @click="get8ECGHolter(scope3.row.uid)">
                8导联
              </el-button> -->
            <el-button @click="getTem(scope3.row.uid)">
              妇温宝
            </el-button>
            <el-button @click="getBaby(scope3.row.uid)">
              胎心仪
            </el-button>
            <el-button slot="reference" type="primary">查看</el-button>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column label="孕期" prop="pregnancy" width="60"></el-table-column>
      <el-table-column label="关联检测">
        <el-table-column label="体重(kg)" prop="weight" width="80"> </el-table-column>
        <el-table-column label="血压(mmHg)" prop="pressure" width="80"> </el-table-column>
        <el-table-column label="血糖(mmol/L)" prop="sugar" width="80">
        </el-table-column>
        <el-table-column label="血氧(%)" prop="oxygen" width="80"> </el-table-column>
        <el-table-column label="尿检" prop="urine" width="80">
          <template v-slot="scope">
            <el-tag :type="scope.row.urine === '正常' ? 'success' : 'warning'" disable-transitions
              style="font-size: 14px;">{{ scope.row.urine }}
            </el-tag>
          </template>
        </el-table-column>

      </el-table-column>

      <el-table-column label="备注">
        <template slot-scope="scope">
          <el-col :span="15">
            <el-input :rows="1" placeholder="请输入内容" v-model="scope.row.remarks">
            </el-input>
          </el-col>
          <el-col :span="6">
            <el-button @click="updateRemark(scope.row)" type="primary" plain size="small">保存</el-button>
          </el-col>
        </template>
      </el-table-column>

      <el-table-column label="操作">
        <template v-slot:default="scope">
          <el-link type="primary" @click="psInfo(scope.row)">{{ '个人信息表' }}</el-link>
          <span>&nbsp&nbsp&nbsp&nbsp</span>
          <el-link type="primary" @click="">{{ '上报' }}</el-link>
          <span>&nbsp&nbsp&nbsp&nbsp</span>
          <el-button type="danger" @click="handleDelete(scope.$index, scope.row)" size="mini"><i
              class="el-icon-delete"></i></el-button>
        </template>
      </el-table-column>

    </el-table>

    <!-- <el-dialog :visible.sync="psInfoVisible">
        <ps_information/>
      </el-dialog> -->
    <div style="padding-top: 40px">
      <div style="font-size: 24px;padding-bottom: 10px">添加新用户</div>
      <el-col :span="6">
        <el-input :rows="1" placeholder="请输入手机号或用户名" v-model="userNameOrPhone">
        </el-input>
      </el-col>
      <el-col :span="4" :offset="1">
        <el-button @click="addNewUser" type="primary">添加</el-button>
      </el-col>
    </div>
    <div v-if="psInfoVisible"> <!-- 关闭后销毁dialog框 -->
      <el-dialog :visible.sync="psInfoVisible">
        <ps_information :userId="this.uidCurrent" />
      </el-dialog>
    </div>
  </div>
</template>
      
<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import ps_information from '../../personal_module/ps_info/ps_information.vue'
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    ps_information
  },
  data() {
    //这里存放数据
    return {
      size: '',
      psInfoVisible: false,
      uidCurrent: '',
      tableData: [
        {
          uid: '1',
          realName: '田圆圆',
          phone: '13244567890',
          weight: 68,
          pressure: '120/90',
          sugar: 2,
          oxygen: 98,
          urine: '正常',
          pregnancy:'18周',
        },
        {
          uid: '2',
          realName: '张异凡',
          phone: '18023457332',
          weight: 70,
          pressure: '112/76',
          sugar: 4,
          oxygen: 99,
          urine: '异常',
          pregnancy:'20周',

        },
        {
          uid: '590',
          realName: '刘金磊',
          phone: '13598856743',
          weight: 77,
          pressure: '130/88',
          sugar: 14.1,
          oxygen: 98,
          urine: '正常',
          pregnancy:'30周',

        },
      ],
      userNameOrPhone: '',
      search: ''
    };
  },
  //监听属性 类似于data概念
  computed: {
    crossPlatformStatus: {
      get() { return this.$store.state.module.crossPlatform },
      set(val) { this.$store.commit('module/updateCrossPlatform', val) }
    }
  },
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {

    handleDelete(index, row) {
      console.log("看看删除啥", index, row);
      console.log(row.uid, row.reid, this.$store.state.doctor_station.docid);
      this.$confirm('此操作将删除该用户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl("/doctor/doctorpersonaluserrelation/deleteUser"),
          method: "post",
          data: this.$http.adornData({
            docId: this.$store.state.women_baby.uid,
            uid: row.uid,
            reid: row.reid
          }),
        }).then(({ data }) => {
          if (data && data.code === 0) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.getUserList()
          } else {
            console.log(data);
            this.$message({
              type: 'info',
              message: data.msg
            });
          }
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    getUserList() {

      this.$http({
        url: this.$http.adornUrl("/doctor/doctorpersonaluserrelation/list"),
        method: "post",
        data: this.$http.adornData({
          docId: this.$store.state.women_baby.uid
        }),
      }).then(({ data }) => {
        if (data && data.code === 0) {
          console.log("看看数据2023年1月9日");
          console.log(data);
          this.tableData = data.relationEntities
        } else {
          console.log(data);
          this.$message({
            message: data.msg,
            type: "warning",
          });
        }
      });
    },
    //   getECG(e){
    //     console.log("看看2023年1月8日");
    //     console.log(e);
    //     this.crossPlatformStatus = true
    //     let uid = e
    //     this.$router.push({ name: 'HistorySingoJump',
    //       query: {
    //         uid: uid,
    //       } })
    //   },
    //   getECGHolter(e){
    //     console.log("看看2023年1月8日");
    //     console.log(e);
    //     this.crossPlatformStatus = true
    //     let uid = e
    //     this.$router.push({ name: 'HistorySingoHolterJump',
    //       query: {
    //         uid: uid,
    //       } })
    //   },
    //   get8ECGHolter(e){
    //     console.log("看看2023年1月8日");
    //     console.log(e);
    //     this.crossPlatformStatus = true
    //     let uid = e
    //     this.$router.push({ name: 'HistoryEightJump',
    //       query: {
    //         uid: uid,
    //       } })
    //   },
    getTem(e) {
      let uid = e
      this.$router.push({
        name: 'HistoryTem_women',
        query: {
          uid: uid,
        }
      })
    },
    getBaby(e) {
      let uid = e
      this.$router.push({
        name: 'HistoryBaby',
        query: {
          uid: uid,
        }
      })
    },
    addNewUser() {
      if (this.userNameOrPhone == "") {
        this.$alert('未填写用户信息', '提示', {
          confirmButtonText: '确定',
          callback: action => {
          }
        });
      } else {
        console.log("看看docId", this.$store.state.doctor_station.docid, this.userNameOrPhone);
        console.log(typeof (this.$store.state.doctor_station.docid));
        let that = this
        this.$http({
          url: this.$http.adornUrl("/doctor/doctorpersonaluserrelation/addUser"),
          method: "post",
          data: this.$http.adornData({
            userPhone: that.userNameOrPhone,
            docId: this.$store.state.women_baby.uid
          }),
        }).then(({ data }) => {
          if (data && data.code === 0) {
            console.log("看看数据");
            console.log(data);
            this.$message({
              message: "添加成功！",
              type: "success",
            });
            this.userNameOrPhone = ""
            this.getUserList()
          } else {
            console.log(data);
            this.$message({
              message: data.msg,
              type: "warning",
            });
          }
        });
      }
    },
    updateRealName(e) {
      this.$http({
        url: this.$http.adornUrl("/doctor/doctorpersonaluserrelation/updateUserRealName"),
        method: "post",
        data: this.$http.adornData({
          uid: e.uid,
          realName: e.realName
        }),
      }).then(({ data }) => {
        if (data && data.code === 0) {
          console.log("看看数据2023年1月9日");
          console.log(data);
        } else {
          console.log(data);
          this.$message({
            message: data.msg,
            type: "warning",
          });
        }
      });
    },
    updateRemark(e) {
      this.$http({
        url: this.$http.adornUrl("/doctor/doctorpersonaluserrelation/updateRemarks"),
        method: "post",
        data: this.$http.adornData({
          reid: e.reid,
          remarks: e.remarks
        }),
      }).then(({ data }) => {
        if (data && data.code === 0) {
          console.log("看看数据2023年1月9日");
          console.log(data);
        } else {
          console.log(data);
          this.$message({
            message: data.msg,
            type: "warning",
          });
        }
      });
    },
    psInfo(e) {
      this.psInfoVisible = true
      this.uidCurrent = e.uid

    },
    cellStyle({ row, column, rowIndex, columnIndex }) {

      if (columnIndex == 7) {
        if (row.sugar > 6.1) {
          return 'background: #F6BEB8 ';
        }
        if (row.sugar < 3.9) {
          return 'background: rgb(234 215 79) ';
        }
      }


    },

  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    console.log(this.$store.state.women_baby.uid)
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    // this.getUserList()
  },
  beforeCreate() { }, //生命周期 - 创建之前
  beforeMount() { }, //生命周期 - 挂载之前
  beforeUpdate() { }, //生命周期 - 更新之前
  updated() { }, //生命周期 - 更新之后
  beforeDestroy() { }, //生命周期 - 销毁之前
  destroyed() { }, //生命周期 - 销毁完成
  activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped>
.drawerTxt {
  margin-left: 10px;
  margin-top: 15px;
  font-size: 25px;
}

.el-table .warning-row {
  background: oldlace;
}
</style>
      